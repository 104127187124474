@import '../../styles/propertySets.css';

.root {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.rules {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/** --------------------GLOBAL------------------------ */


.sectionTitle  {

  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 18px;
  font-weight: var(--fontWeightSemiBold);
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionSubTitle {
  color: var(--matterColorAnti);
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: var(--fontWeightMedium);
}

.sectionCaption {
  font-size: 17px;
  font-weight: var(--fontWeightSemiBold);
  margin-bottom: 10px;
}


/** --------------------DESCRIPTION------------------------ */

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 25px;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}


/** --------------------CAPACITY------------------------ */

.sectionCapacity {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}
.sectionCapacityContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 10px;
}
.sectionCapacityContent p {
  margin: 0;
  color: var(--matterColorAnti)
}

.sectionCapacityIcon {
  height: 25px;
}



.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 20px;
  }
}


/** --------------------FEATURES------------------------ */


.sectionFeaturesWrapper {
  display: flex;
  flex-wrap: wrap;
}
.sectionFeaturesWrapper p {
  min-width: 200px;
  margin: 0;
  color: var(--matterColor)
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatureIconCheck {
  height: 10px;
  margin-right: 10px;
}

.infoComp p {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 30px;
}

/** --------------------HOST------------------------ */

.sectionHost {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.sectionHost .avatar {
  height: 45px;
  width: 45px;
  margin-right: 20px;

}

.sectionHostProfil p {
  margin: 0;
  line-height: 15px;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
}

.sectionHostEditButton {
    background-color: var(--matterColorFilter);
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 4px;

    &:hover {
      background-color: var(--matterColorRollover);
    }
}

.sectionHostEditButton a {
  color: var(--matterColorDark);
  text-decoration: underline;
}

.contactButton {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.pendingApproval {
  /* background-color: var(--matterColorFilter); */
  padding: 5px 10px;
  /* margin-bottom: 20px; */
  border-radius: 6px;
}
